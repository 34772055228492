export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    route: 'dashboard-analytics',
    permissions: ['dashboard'],
  },

  {
    header: 'notifications_settings',
    permissions: ['notifications'],
  },
  {
    title: 'notifications_sent',
    icon: 'CircleIcon',
    route: 'notifications',
    permissions: ['notifications'],
  },
  {
    title: 'notifications_messages',
    icon: 'CircleIcon',
    route: 'notification-messages',
    permissions: ['notifications'],
  },
  {
    header: 'logs',
    permissions: ['logs'],
  },
  {
    title: 'real-state-status-logs',
    icon: 'CircleIcon',
    route: 'real-state-status-logs',
    permissions: ['logs'],
  },
  {
    title: 'real-state-deleted-logs',
    icon: 'CircleIcon',
    route: 'real-state-deleted-logs',
    permissions: ['logs'],
  },
  {
    title: 'real-state-updated-logs',
    icon: 'CircleIcon',
    route: 'real-state-updated-logs',
    permissions: ['logs'],
  },


  {
    header: 'Real Estate',
    permissions: ['real-estate'],
  },
  {
    title: 'licensed_real_estate',
    icon: 'HomeIcon',
    route: 'real-estate-licensed',
    permissions: ['real-estate'],
  },
  {
    title: 'not_licensed_real_estate',
    icon: 'HomeIcon',
    route: 'real-estate-options',
    permissions: ['real-estate'],
  },

  {
    header: 'Requests List',
    permissions: ['real-estate-requests','marketing-request'],
  },
  {
    title: 'Marketing requests',
    icon: 'HomeIcon',
    route: 'marketing-request',
    permissions: ['marketing-request'],
  },

  {
    title: 'Real Estate Requests',
    icon: 'ListIcon',
    route: 'real-estate-requests',
    permissions: ['real-estate-requests'],
  },
  {
    header: 'packages-settings',
    permissions: ['packages'],
  },
  {
    title: 'packages',
    icon: 'HomeIcon',
    route: 'package',
    permissions: ['packages'],
  },
  {
    title: 'package-detail',
    icon: 'HomeIcon',
    route: 'package-detail',
    permissions: ['packages'],
  },
  {
    title: 'payment-method',
    icon: 'HomeIcon',
    route: 'payment-method',
    permissions: ['packages'],
  },

  {
    header: 'Real Estate Settings',
    permissions: ['realstateusage', 'realstatetype', 'amenity'],
  },

  {
    title: 'usages',
    icon: 'HomeIcon',
    route: 'real-state-usage',
    permissions: ['realstateusage'],
  },
  {
    title: 'Real Estate Types',
    icon: 'HomeIcon',
    route: 'real-state-type',
    permissions: ['realstatetype'],
  },
  {
    title: 'Real Estate Amenities',
    icon: 'HomeIcon',
    route: 'amenities',
    permissions: ['amenity'],
  },

  {
    header: 'fields_settings',
    permissions: ['fields', 'types-category', 'types'],
  },

  {
    title: 'fields',
    icon: 'ListIcon',
    route: 'field',
    permissions: ['fields'],
  },
  {
    title: 'Types Parent Categories',
    icon: 'HomeIcon',
    route: 'type-parent-category',
    permissions: ['types'],
  },
  {
    title: 'Types Categories',
    icon: 'HomeIcon',
    route: 'type-category',
    permissions: ['types'],
  },

  // {
  //   title: 'Related Service',
  //   icon: 'ListIcon',
  //   route: 'related-service',
  //   permissions: ['related-service'],
  // },
  // {
  //   title: 'Real Estate View',
  //   icon: 'ListIcon',
  //   route: 'real-estate-view',
  //   permissions: ['real-estate-view'],
  // },

  {
    header: 'Roles & Users',
    permissions: ['user', 'roles', 'customer'],
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    route: 'users',
    permissions: ['user'],
  },
  {
    title: 'Roles & Permissions',
    icon: 'UsersIcon',
    route: 'roles',
    permissions: ['roles'],
  },
  {
    title: 'Customers',
    icon: 'UserIcon',
    route: 'customers',
    permissions: ['customer'],
  },
  // {
  //   title: 'Audits',
  //   icon: 'UserIcon',
  //   route: 'audits',
  //   permissions: ['audit'],
  // },
  // {
  //   header: 'Facilities',
  //   permissions: ['facility-district', 'facility-type'],
  // },
  // {
  //   title: 'Facilities Districts',
  //   icon: 'ListIcon',
  //   route: 'facilities-districts',
  //   permissions: ['facility-district'],
  // },
  // {
  //   title: 'Facility Types',
  //   icon: 'ListIcon',
  //   route: 'facilities-types',
  //   permissions: ['facility-type'],
  // },
  // {
  //   title: 'District facility categories',
  //   icon: 'ListIcon',
  //   route: 'district-facility-categories',
  //   permissions: ['district-facility-category'],
  // },
  // {
  //   title: 'District Facilities',
  //   icon: 'ListIcon',
  //   route: 'district-facilities',
  //   permissions: ['district-facility'],
  // },
  {
    header: 'country_and_region_settings',
    permissions: ['countries', 'region', 'city', 'district'],
  },
  // {
  //   title: 'Countries',
  //   icon: 'MapIcon',
  //   route: 'countries',
  //   permissions: ['countries'],
  // },
  {
    title: 'Regions',
    icon: 'MapIcon',
    route: 'regions',
    permissions: ['region'],
  },
  {
    title: 'Cities',
    icon: 'MapIcon',
    route: 'cities',
    permissions: ['city'],
  },
  // {
  //     title: 'Directions',
  //     icon: 'MapIcon',
  //     route: 'directions',
  //     permissions: ['cities'],
  // },
  {
    title: 'Districts',
    icon: 'MapIcon',
    route: 'districts',
    permissions: ['district'],
  },
  {
    header: 'general_settings',
    permissions: ['settings', 'faq', 'page'],
  },
  {
    title: 'Setting',
    icon: 'SettingsIcon',
    route: 'settings',
    permissions: ['setting'],
  },

  {
    title: 'FAQs',
    icon: 'HelpCircleIcon',
    route: 'faqs',
    permissions: ['faq'],
  },
  {
    title: 'Pages',
    icon: 'BookOpenIcon',
    route: 'pages-builder',
    permissions: ['page'],
  },
  // {
  //   title: 'Services',
  //   icon: 'ListIcon',
  //   route: 'services',
  //   permissions: ['service'],
  // },
  {
    header: 'technical support',
    permissions: ['contact-us', 'conflict'],
  },
  {
    title: 'Contact Us',
    icon: 'PhoneIcon',
    route: 'contact-us',
    permissions: ['contact-us'],
  },
  {
    title: 'Conflicts',
    icon: 'FlagIcon',
    route: 'conflicts',
    permissions: ['conflict'],
  },
  {
    header: 'Blogs and news',
    permissions: ['blog', 'newsletter'],
  },
  {
    title: 'Blogs',
    icon: 'CircleIcon',
    route: 'blogs',
    permissions: ['blog'],
  },
  // {
  //   title: 'Blog Categories',
  //   icon: 'CircleIcon',
  //   route: 'blog-categories',
  //   permissions: ['blog categories'],
  // },
  {
    title: 'News Letter',
    icon: 'MailIcon',
    route: 'newsletter',
    permissions: ['newsletter'],
  },

]
